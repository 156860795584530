/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.2
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2022 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'




// router setup
import routes from './routes/routes'

import { firebaseConfig } from './constants/config'


// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)



//firebase.initializeApp(firebaseConfig);
//import firebase from 'firebase/app'
//import 'firebase/auth'

Vue.config.productionTip = false

export const eventBus = new Vue();

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active'
});

Vue.filter('toCurrencyFormat', function (value) {    
  if(value===undefined) {
      value = 0.00;    
    }
    //return "$ " + value.toFixed(3).replace(/(\d)(?=(\d{2})+(?:\.\d+)?$)/g, "$1,"); 
    return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"); 
})

Vue.filter('toPercentFormat', function (value) {    
  if(value===undefined) {
      value = 0.00;    
    }    
    return "% " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "%1,"); 
 })  

/* eslint-disable no-new */

new Vue({
  el: '#app',
  render: h => h(App),
  router
})
