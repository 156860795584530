import axios from 'axios';

export const HTTP = axios.create({
  
  // MAU PROD
  //baseURL: `https://201.168.125.94/authf`,
  baseURL: `https://empleado.datatools.mx/authf`,

  
  // MAU DEV
  //baseURL: `https://localhost/authf`,

})
