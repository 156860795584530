<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Acceso</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="email"
                               :error="failed ? 'El Usuario es requerido': null"
                               :hasSuccess="passed"
                               label="Usuario"
                               name="email"
                               v-model="email"
                               autocomplete="off">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }">

                    <fg-input  type="password"
                               :error="failed ? 'La contraseña es requerida': null"
                               :hasSuccess="passed"
                               name="password"
                               label="Contraseña"
                               v-model="password"
                               autocomplete="off">
                    </fg-input>
                  </ValidationProvider>
                  <!-- <fg-input>
                    <l-checkbox v-model="subscribe">
                      Subscribe to newsletter
                    </l-checkbox>
                  </fg-input> -->
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Entrar</button>
                  <br>
                  <!-- <div class="forgot">
                    <router-link to="/register" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div> -->
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { loginService } from "../../../modulos/utilerias/servicios/loginservice";
import Swal from 'sweetalert2'

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      // LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data() {
      return {
        email: "",
        password: "",
        subscribe: true,
        user :[]
      };
    },
    methods: {
      submit() {
        let modelo ={
          usuario: this.email,
          contrasenia: this.password

        };
        
        
        loginService.validarlogin(modelo).then((response) => {
          this.user = {
            id : response.data.id,
            key: response.data.key,
            nombre: response.data.nombre,
            token: response.data.token
          };

          //localStorage.setItem('user',JSON.stringify(thi.user));

          localStorage.setItem('id', response.data.id);
          localStorage.setItem('key', response.data.key);
          localStorage.setItem('nombre', response.data.nombre);
          localStorage.setItem('token', response.data.token);

          if (response.data.key ==='dante.teytud@fmexi.com'){
            this.$router.push('/fone/empfeddist');
          }else 
          {
            this.$router.push('/operacion/dashopera');
          }

          
        
        }).catch((error)=> {
          //console.log(error);
          //alert("Usuario o Contraseña no validos.");
          Swal.fire({
            title: `Usuario o Contraseña no validos.`,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-danger btn-fill'
          })
        });

        
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
