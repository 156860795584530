import { HTTP } from '../../../core/utils/http';

export const loginService = ({  

  // MAU PROD
  //baseUrl : 'https://201.168.125.94/authf/api/v1/auth/',
  baseUrl : 'https://empleado.datatools.mx/authf/api/v1/auth/',
  

  // MAU DEV
  //baseUrl : 'http://localhost/authf/api/v1/auth/',
  
  validarlogin(modelo) {
    return HTTP.post(this.baseUrl + 'login/', modelo);
  }              

})

