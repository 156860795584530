//import { UserRole } from "../utils/auth.roles";

export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`


export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

//export const defaultLocale = 'en'
export const defaultLocale = 'es'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'en', name: 'English LTR', direction: 'ltr' },  
  { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
]

export const firebaseConfig = {
  apiKey: "AIzaSyD6ZnqLALtNkBk5R8YteNFfMjxJO5mo028",
    authDomain: "stproject-66d77.firebaseapp.com",
    projectId: "stproject-66d77",
    storageBucket: "stproject-66d77.appspot.com",
    messagingSenderId: "311650524577",
    appId: "2:311650524577:web:c2047d2784da9c74eeffe0",
    measurementId: "G-VEXEHNP3QG"
};

export const currentUser = {
  id: 1,
  title: 'Credit Tools',
  /*img: '/assets/img/profiles/l-1.jpg',*/
  img: '/assets/img/profiles/default.jpg',  
  date: 'Last seen today 15:24',  
  role: '',
  uuid:'',
  token:'',
  key:'',
}

//export const isAuthGuardActive = false;
export const isAuthGuardActive = true;
export const themeRadiusStorageKey = 'theme_radius'
export const themeSelectedColorStorageKey = 'theme_selected_color'
export const defaultColor = 'light.blueolympic'
export const colors = ['bluenavy', 'blueyale', 'blueolympic', 'greenmoss', 'greenlime', 'purplemonster', 'orangecarrot', 'redruby', 'yellowgranola', 'greysteel']
