import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
import Panels from 'src/pages/Dashboard/Components/Panels.vue'
const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'
import { path } from 'd3'
import VueRouter from 'vue-router'

import AuthGuard from "../util/auth.guard"

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')

// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')

// Pages Desarrolladas
// Catálogos
const Convenios = () => import('../modulos/Catalogos/views/convenios/Indice.vue')
const Clientes = () => import('../modulos/Catalogos/views/clientes/Indice.vue')
const Cartera = () => import('../modulos/Catalogos/views/cartera/Indice.vue')

// Operacion
const Edocta = () => import('../modulos/operacion/views/edocuenta/Indice.vue')
const Coblist = () => import('../modulos/operacion/views/cobranzalista/Indice.vue')
const Dashopera = () => import('../modulos/operacion/views/dashboard/Indice.vue')
const Termcuenta = () => import('../modulos/operacion/views/terminaroncuenta/Indice.vue')
const Emplescuela = () => import('../modulos/operacion/views/empleadoescuela/Indice.vue')
const Escmunicipio = () => import('../modulos/operacion/views/escuelasdistribucion/Indice.vue')

const Pruebas = () => import('../modulos/operacion/views/pruebas/Indice.vue')

// Fone
const Empfed = () => import('../modulos/fone/views/empleados/Indice.vue')
const Empfedxcurp = () => import('../modulos/fone/views/xcurp/Indice.vue')
const Empfeddist = () => import('../modulos/fone/views/distribucionemp/Indice.vue')
const Empfedxcurplist = () => import('../modulos/fone/views/xcurplistado/Indice.vue')
const Prosfeddist = () => import('../modulos/fone/views/prospectosgrafica/Grafica.vue')
const Prosfeddistlist = () => import('../modulos/fone/views/prospectoslistado/Indice.vue')
const Mapprosfeddistlist = () => import('../modulos/fone/views/mapaprospectos/Indice.vue')

// Premium
const Movimientose = () => import('../modulos/Reportes/views/reportes/edomovto/Indice.vue')
const Issteinfo = () => import('../modulos/Reportes/views/reportes/isste/Indice.vue')

// Nubarium
const Valcurps = () => import('../modulos/nubarium/views/validarcurp/Indice.vue')
const Gencurp = () => import('../modulos/nubarium/views/gencurp/Indice.vue')

let catalogosMenu = {
  path: '/catalogos',
  component: DashboardLayout,
  redirect: '/catalogos/clientes',
  children:[
    {
      path: 'convenios',
      name: 'Convenios',
      component: Convenios
    },
    {
      path: 'clientes',
      name: 'Clientes',
      component: Clientes
    },
    {
      path: 'cartera',
      name: 'Cartera',
      component: Cartera
    }
  ]

}
let operacionMenu = {
  path: '/operacion',
  component: DashboardLayout,
  redirect: '/operacion/edocta',
  children:[
    {
      path: 'dashopera',
      name: 'Dashboard de Listados de Cobranza',
      component: Dashopera
    },
    {
      path: 'edocta',
      name: 'Estado de Cuenta',
      component: Edocta
    },
    {
      path: 'coblist',
      name: 'Cobranza de Listado',
      component: Coblist
    },
    {
      path: 'termcuenta',
      name: 'Empleados que Terminaron Cuentas en Último Listado',
      component: Termcuenta
    },
    {
      path: 'emplescuela',
      name: 'Empleados y Escuelas',
      component: Emplescuela
    },
    {
      path: 'escmunicipio',
      name: 'Escuelas por Municipio',
      component: Escmunicipio
    },
    {
      path: 'pruebas',
      name: 'Grafica de pruebas',
      component: Pruebas
    }
  ]

}

let foneMenu = {
  path: '/fone',
  component: DashboardLayout,
  redirect: '/fone/empfed',
  children:[
    {
      path: 'empfed',
      name: 'Listado de Empleados',
      component: Empfed
    },
    {
      path: 'empfedxcurp',
      name: 'Buscar Empleados por CURP',
      component: Empfedxcurp
    },
    {
      path: 'empfedxcurplist',
      name: 'Buscar Empleados por CURP Listado',
      component: Empfedxcurplist
    },
    {
      path: 'empfeddist',
      name: 'Distribución de Empleados Fone',
      component: Empfeddist
    },
    {
      path: 'prosfeddist',
      name: 'Prospectos de Empleados Fone',
      component: Prosfeddist
    },
    {
      path: 'prosfeddistlist',
      name: 'Prospectos de Empleados Fone Listado',
      component: Prosfeddistlist
    },
    {
      path: 'mapprosfeddistlist',
      name: 'Mapa de Prospectos',
      component: Mapprosfeddistlist
    }
  ]

}

let nubariumMenu = {
  path: '/tools',
  component: DashboardLayout,
  redirect: '/tools/valcurps',
  children:[
    {
      path: 'valcurps',
      name: 'Validar CURP',
      component: Valcurps
    },
    {
      path: 'gencurp',
      name: 'Obtener CURP',
      component: Gencurp
    }
  ]

}


let avanzadoMenu = {
  path: '/avanzado',
  component: DashboardLayout,
  redirect: '/avanzado/movimientose',
  children:[
    {
      path: 'movimientose',
      name: 'Movimientos Empleados 50',
      component: Movimientose
    },
    {
      path: 'issteinfo',
      name: 'Información de Issste',
      component: Issteinfo
    }
  ]

}
let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    redirect: '/login'
    //redirect: '/admin/overview'
  },
  catalogosMenu,
  operacionMenu,
  foneMenu,
  avanzadoMenu,
  componentsMenu,
  formsMenu,
  nubariumMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  {path: '*', component: NotFound}
]

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
});

router.beforeEach(AuthGuard);
export default routes
